// Tooltip
$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})

$('.carousel').carousel({
    interval: 8000
})


//Modal
$(window).on('load',function(){
    $('#alertmodal').modal('show');
});

// Acordion chevron ikonu 
$(".collapse").on('shown.bs.collapse', function(e){
    $(this).prev(".card-header").find(".fas").removeClass("fa-angle-double-down").addClass("fa-angle-double-up");
}).on('hide.bs.collapse', function(){
    $(this).prev(".card-header").find(".fas").removeClass("fa-angle-double-up").addClass("fa-angle-double-down");
});

// Acordion üste çıkması 
$('.collapse').on('shown.bs.collapse', function(e) {
    var $card = $(this).closest('.card');
    $('html,body').animate({
        scrollTop: $card.offset().top
    }, 500);
});

// Back yada refres yapınca acordion açık gelmesi
$(".collapse").on("hidden.bs.collapse", function() {
    localStorage.setItem("coll_" + this.id, false);
});
$(".collapse").on("shown.bs.collapse", function() {
    localStorage.setItem("coll_" + this.id, true);
});
$(".collapse").each(function() {
    if (localStorage.getItem("coll_" + this.id) == "true") {
        $(this).collapse("show");
    }
});

// Kategori link 
$('.tocategory-link').click(function(){
    $('html, body').animate({
        scrollTop: $( $(this).attr('href') ).offset().top
    }, 500);
    return false;
});
